import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useStyles } from "../styles/index.styles";
import { useNavigate } from "react-router-dom";
import AvatarHiplade from "../../common/components/avatarhiplade";
import StationsService from "../../services/StationsService";
import StreamingService from "../../services/StreamingService";
import colors from "../../common/styles/colors";
import loadstatus from "../../assets/images/load-status.gif";
import AttachmentService from "../../services/AttachmentService";
import DebriefingService from "../../services/DebriefingService";
import { toast } from "react-toastify";
import LoaderHiplade from "../../common/components/loaderhiplade";

export default function StationStudentsList({
  studentsgroupId,
  eventId,
  stationId,
  status,
  station,
}: any) {
  if (!studentsgroupId) {
    return <Box />;
  }

  const { t } = useTranslation();

  const arrStatus = ["not_corrected", "correcting", "corrected", "revised"];
  const arrErrorStatus = [
    "error_create_streaming_hiplade",
    "error_merdiaservice_create_streaming",
    "error_configuring_obs",
    "rtmp_connection_failed",
    "error_hiplade_transmition_method",
    "error_hiplade_useffmpeg",
    "error_agent",
    "error_rca",
  ];

  const [students, setStudents] = useState([] as any);
  const [inSimulation, setInSimulation] = useState(false);

  const styles = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const getStudentsList = async (stationId: any) => {
    await StationsService.getUsersStations(stationId)
      .then((response: any) => {
        if (response.data && response.data.evaluateds.length > 0) {
          setStudents(response.data.evaluateds);
          const status = response.data.in_simulation === "true" ? true : false;
          setInSimulation(status);
        }
      })
      .catch((e: Error) => {});
  };

  const startDebriefing = async (
    studentId: any,
    eventId: any,
    stationId: any,
    debriefingId: any
  ) => {
    navigate(
      `/events/scenarios/view/${eventId}/${stationId}/${studentId}/${debriefingId}`
    );
  };

  const colorStatus = (status: any) => {
    if (status == "initializing" || status == "processing_recording") {
      return colors.purpleStatus;
    } else if (status == "not_corrected" || status == "in_simulation") {
      return colors.pinkLight;
    } else if (status == "correcting") {
      return colors.yellow;
    } else if (arrErrorStatus?.includes(status)) {
      return colors.pink;
    }
    {
      return colors.greenMedium;
    }
  };

  const startSimulation = async (evaluated_id: any) => {
    const data = {
      evaluated_id: evaluated_id,
      station_id: stationId,
      event_id: eventId,
      name: `Streaming ${evaluated_id}${stationId}${eventId}`,
    };
    await StreamingService.store(data)
      .then((response: any) => {
        if (response.data) {
          getStudentsList(stationId);
        }
      })
      .catch((e: Error) => {});
  };

  const stopSimulation = async (streaming_id: any) => {
    await StreamingService.stop(streaming_id)
      .then((response: any) => {
        if (response.data) {
          getStudentsList(stationId);
        }
      })
      .catch((e: Error) => {});
  };

  const storeDebriefing = async (
    appraiser_id: any,
    evaluated_id: any,
    e: any
  ) => {
    if (e.target.files[0].type != "video/mp4") {
      toast(t("toast_type_video"), {
        type: "error",
      });
      return;
    }

    setLoader(true);
    const data = {
      evaluated_id: evaluated_id,
      station_id: stationId,
      event_id: eventId,
      appraiser_id: appraiser_id,
      status: "not_corrected",
    };
    await DebriefingService.store(data)
      .then((response: any) => {
        if (response.data) {
          const debriefing_id = response.data.debriefing_id;
          uploadFile(e, debriefing_id);
        }
      })
      .catch((e: Error) => {
        setLoader(false);
      });
  };

  const uploadFile = async (e: any, debriefing_id: any) => {
    const data = {
      feature: "debriefing",
      object_id: debriefing_id,
      field_name: "debriefing_video",
      file: e.target.files[0],
      file_type: "video",
      multiple: "false",
    };

    await AttachmentService.upload(data)
      .then((response) => {
        setLoader(false);
        getStudentsList(stationId);
        toast(t("toast_load_video"), {
          type: "success",
        });
      })
      .catch((e: any) => {
        setLoader(false);
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (stationId) {
      getStudentsList(stationId);
    }
  }, [studentsgroupId, eventId, stationId]);

  return (
    <Box>
      {loader && <LoaderHiplade />}
      {students
        ? students.map((student, index) => (
            <Grid
              container
              key={index}
              spacing={1}
              className={styles.eventStationListStudents}
            >
              <Grid item xs={2} sm={2} md={2}>
                <Typography className={styles.textDetail}>
                  + Detalhes
                </Typography>
              </Grid>

              <Grid
                item
                xs={2}
                sm={6}
                md={6}
                className={styles.boxContentAvatar}
              >
                <AvatarHiplade
                  nameUser={student?.user_name}
                  size="mini"
                  theme="gray"
                  id={student?.user_id}
                />
                <Typography className={styles.titleStudent}>
                  <span style={{ fontWeight: "bold" }}>Aluno (a)/ </span>
                  {student?.user_name}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                className={styles.alignButtonsListStation}
              >
                {status !== "concluded" && !student?.debriefing_status && (
                  <Box>
                    <input
                      id={"arq" + index}
                      name={"arq" + index}
                      style={{ display: "none" }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.files != null) {
                          storeDebriefing(
                            student?.appraisers?.id,
                            student?.user_id,
                            event
                          );
                        }
                      }}
                      accept="application/mp4"
                      type="file"
                    />
                    <label htmlFor={"arq" + index}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          borderRadius: "4px",
                          cursor: "pointer",
                          color: colors.pinkLight,
                          border: "2px solid " + colors.pinkLight,
                          textTransform: "uppercase",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          textAlign: "center",
                          width: "170px !important",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}

                        // className={styleScenario.buttonUpload}
                      >
                        {t("btn_upload_video")}
                      </Typography>
                    </label>
                  </Box>
                )}

                {student?.debriefing_status && (
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: `${colorStatus(student?.debriefing_status)}`,
                    }}
                  >
                    {t(student?.debriefing_status)}
                  </Typography>
                )}
                {(student?.debriefing_status == "initializing" ||
                  student?.debriefing_status == "processing_recording") && (
                  <img src={loadstatus} style={{ marginLeft: 15 }} />
                )}
                {status !== "concluded" &&
                  student?.debriefing_status == "in_simulation" && (
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ ml: 2 }}
                      onClick={() => {
                        navigate(
                          `/events/live/${eventId}/${stationId}/${student?.user_id}/${student?.debriefing_id}`
                        );
                      }}
                    >
                      {t("bt_live")}
                    </Button>
                  )}
                {student?.debriefing_status == "in_simulation" && (
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ ml: 2 }}
                    onClick={() => stopSimulation(student?.streaming_id)}
                  >
                    {t("bt_finish")}
                  </Button>
                )}

                {arrStatus?.includes(student?.debriefing_status) && (
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ ml: 2 }}
                    onClick={() =>
                      startDebriefing(
                        student.user_id,
                        eventId,
                        stationId,
                        student?.debriefing_id
                      )
                    }
                  >
                    {" "}
                    {student?.debriefing_status == "corrected" ||
                    student?.debriefing_status == "revised"
                      ? t("btn_access")
                      : t("bt_correct")}
                  </Button>
                )}
                {station.has_camera &&
                  status !== "concluded" &&
                  (arrErrorStatus?.includes(student?.debriefing_status) ||
                    !student?.debriefing_status) &&
                  !inSimulation && (
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ ml: 2 }}
                      className={styles.buttonStartSimulation}
                      onClick={() => startSimulation(student?.user_id)}
                    >
                      {" "}
                      {t("btn_start_simulation")}
                    </Button>
                  )}
              </Grid>
            </Grid>
          ))
        : "Carregando..."}
    </Box>
  );
}
