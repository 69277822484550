import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import banner from "../../../assets/images/banners/event_form_banner.png";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDefaultStyles } from "../../../common/styles/default";
import { useModal } from "../../../common/hooks/useModal";
import IEntityData from "../../../types/Entities";
import IStudentsGroupData from "../../../types/StudentsGroup";
import EventsService from "../../../services/EventsService";
import EntitiesService from "../../../services/EntitiesService";
import Banner from "../../../common/components/banner";
import TitleForm from "../../../common/components/titleform";
import ListStations from "../ListStations";
import FooterEvent from "../footer";
import { useStyles } from "./styles";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";

export default function FormEvent() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const stylesDefault = useDefaultStyles();
  const styles = useStyles();

  const { eventId } = useParams();

  const { handleOpenModal } = useModal();

  const [entities, setEntities] = useState<IEntityData[]>([]);
  const [name, setName] = useState("");
  const [idGroup, setIdGroup] = useState("");
  const [group, setGroup] = useState<IStudentsGroupData[]>([]);
  const [idEvent, setIdEvent] = useState("");
  const [entityId, setEntityId] = useState("");
  const [typeEvent, setTypeEvent] = React.useState("");
  const [typeEventId, setTypeEventId] = React.useState("");

  const [startTime, setStartTime] = useState("");
  const [finalTime, setFinalTime] = useState("");
  const [timeStation, setTimeStation] = useState("");
  const [addStation, setAddStation] = useState(false);

  const [date, setDate] = useState("");

  //IdGroup
  const handleIdGroup = (event: SelectChangeEvent) => {
    setIdGroup(event.target.value);
  };

  //Entity
  const handleEntity = (event: SelectChangeEvent) => {
    setEntityId(event.target.value);
    getGroup(event.target.value);
  };

  const handleTypeEvent = (event: SelectChangeEvent) => {
    setTypeEventId(event.target.value);
  };

  //criar/editar evento
  const handleSubmit = async () => {
    if (name == "") {
      toast(t("toast_msg_formevents_name"), {
        type: "error",
      });
      return;
    }

    if (entityId == "") {
      toast(t("toast_msg_formevent_entity"), {
        type: "error",
      });
      return;
    }

    if (timeStation == "") {
      toast(t("toast_msg_formevent_timestation"), {
        type: "error",
      });
      return;
    }

    if (idGroup == "") {
      toast(t("toast_msg_formevent_group"), {
        type: "error",
      });
      return;
    }

    // criar(enviar) dados p/ o backend(objeto)
    const dataEvent = {
      name: name,
      date: date,
      start_time: startTime,
      final_time: finalTime,
      type: typeEventId,
      time_station: timeStation,
      studentsgroup_id: idGroup,
      entity_id: entityId,
    };

    if (!idEvent) {
      const response = await EventsService.store(dataEvent);

      if (response.status == 200) {
        const idEvent = response?.data?.id;
        setIdEvent(idEvent.toString());
        setAddStation(true);
        toast(t("toast_msg_form_event_save"), {
          type: "success",
        });
      } else {
        toast(response?.data?.Error, {
          type: "error",
        });
      }

      return;
    } else {
      const response = await EventsService.update(idEvent, dataEvent);

      if (response.status == 200) {
        toast(t("toast_msg_form_event_save"), {
          type: "success",
        });
      } else {
        toast(response?.data?.Error, {
          type: "error",
        });
      }

      return;
      EventsService.update(idEvent, dataEvent)
        .then((response: any) => {
          toast(t("toast_msg_form_event_save"), {
            type: "success",
          });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const getEntities = async () => {
    await EntitiesService.getAll(1, 0)
      .then((response: any) => {
        setEntities(response.data);
      })
      .catch((e: Error) => {});
  };

  const getGroup = async (entity_id) => {
    await EventsService.getGroup(entity_id)
      .then((response: any) => {
        setGroup(response.data);
      })
      .catch((e: Error) => {});
  };

  const loadStations = async () => {
    return;
  };

  const getCurrentEvent = async (idEvent) => {
    EventsService.get(idEvent)
      .then((response: any) => {
        let event = response.data;

        setName(event.name);
        setEntityId(event.entity_id);

        getGroup(event.entity_id);
        setIdGroup(event.studentsgroup_id);

        setDate(event.date);
        setStartTime(event.start_time);
        setFinalTime(event.final_time);
        setTimeStation(event.time_station);
        setTypeEventId(event.type);

        setAddStation(true);
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const handleToAddStation = () => {
    navigate(`/events/station/form/${idEvent}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAreaEvent = () => {
    navigate("/events/area");
  };

  useEffect(() => {
    getEntities();

    if (eventId) {
      setIdEvent(eventId);
      getCurrentEvent(eventId);
    }
  }, []);

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={t("banner_title_event_form")}
        icon={
          <TodayOutlinedIcon
            sx={{ fontSize: "4.3em", color: "white", ml: 10, mr: 5 }}
          />
        }
        //subtitle={t("banner_subtitle_event_form")}
        //divider="1"
      />
      <Box sx={{ marginTop: 12, px: 20 }} className={stylesDefault.container}>
        <TitleForm title={t("title_page_form_event")} />

        <Box className={styles.container} component="form" noValidate>
          <Box className={styles.wrapper}>
            <Box sx={{ flex: 1 }}>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label={t("input_label_form_events")}
                variant="outlined"
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
              />
            </Box>
            <Box className={styles.box}>
              {t("label_form_event_date")}
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                value={date}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDate(event.target.value);
                }}
              />
            </Box>
          </Box>

          <Box className={styles.wrapper}>
            <Box className={styles.box} sx={{ flex: 2 }}>
              <Box className={styles.box}>{t("label_form_event_duration")}</Box>
              <Box sx={{ gap: 2 }} className={styles.box}>
                <TextField
                  id="start_time"
                  fullWidth
                  variant="outlined"
                  type="time"
                  value={startTime}
                  onChange={(event) => {
                    setStartTime(event.target.value || "");
                  }}
                  inputProps={{
                    min: "00:00:00",
                    max: "23:59:59",
                  }}
                />
                {t("label_form_event_duration_up_to")}
                <TextField
                  fullWidth
                  variant="outlined"
                  type="time"
                  value={finalTime}
                  onChange={(event) => {
                    setFinalTime(event.target.value || "");
                  }}
                  inputProps={{
                    min: "00:00:00",
                    max: "23:59:59",
                  }}
                />
              </Box>
            </Box>
            <Box className={styles.grid}>
              <FormControl
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  minWidth: 180,
                }}
              >
                <InputLabel id="event-type-label">
                  {t("input_label_event_type")}
                </InputLabel>
                <Select
                  required
                  labelId="event-type-label"
                  id="event-type"
                  value={typeEventId}
                  label="type_event"
                  onChange={handleTypeEvent}
                >
                  <MenuItem value={1}>{t("title_menu_item_osce")}</MenuItem>
                  <MenuItem value={2}>{t("title_menu_item_proof")}</MenuItem>
                  <MenuItem value={3}>{t("title_menu_item_training")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className={styles.grid}>
              <FormControl
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  minWidth: 180,
                }}
              >
                <InputLabel id="entities-label">
                  {t("input_label_entity")}
                </InputLabel>
                <Select
                  labelId="entities-label"
                  label={t("input_label_entity")}
                  value={entityId}
                  onChange={handleEntity}
                >
                  {entities.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box className={styles.wrapper}>
            <Box
              sx={{ flex: 2, justifyContent: "flex-start" }}
              className={styles.grid}
            >
              {t("label_form_event_time_limit_per_station")}
              <Box>
                <FormControl
                  sx={{
                    fontWeight: "bold",
                    minWidth: 180,
                  }}
                >
                  <InputLabel>{t("time_limit_per_station")}</InputLabel>
                  <Select
                    required
                    labelId="time-station"
                    id="time-station"
                    value={timeStation}
                    label="time-station"
                    //onChange={handleTypeEvent}
                    onChange={(event) => {
                      setTimeStation(event.target.value);
                    }}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    <MenuItem value="00:05:00">05 min</MenuItem>
                    <MenuItem value="00:10:00">10 min</MenuItem>
                    <MenuItem value="00:15:00">15 min</MenuItem>
                    <MenuItem value="00:20:00">20 min</MenuItem>
                    <MenuItem value="00:25:00">25 min</MenuItem>
                    <MenuItem value="00:30:00">30 min</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{ justifyContent: "flex-start", pl: 8 }}
              className={styles.grid}
            >
              <FormControl
                sx={{
                  minWidth: 180,
                }}
              >
                <InputLabel id="group-label">
                  {t("input_label_studentsgroup")}
                </InputLabel>
                <Select
                  labelId="group-label"
                  label={t("input_label_studentsgroup")}
                  value={idGroup}
                  onChange={handleIdGroup}
                >
                  <MenuItem value={1}>
                    {t("input_label_event_studentgroup_select")}
                  </MenuItem>
                  {group.map((row, index) => (
                    <MenuItem key={index} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box className={styles.grid}></Box>
          </Box>

          <Box>
            <Button variant="contained" size="large" onClick={handleSubmit}>
              {" "}
              {t("btn_save")}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 10 }}>
          <TitleForm title={"Cadastre a estação"} />
          <Button
            variant="contained"
            size="large"
            onClick={handleToAddStation}
            disabled={!addStation}
          >
            {" "}
            {t("btn_add_station")}
          </Button>
          <ListStations eventId={eventId} />
        </Box>
      </Box>
      <FooterEvent
        onClickAction={handleBack}
        onClickAreaEvent={handleAreaEvent}
        handleSubmit={handleSubmit}
        idEvent={idEvent}
      />
    </Box>
  );
}
